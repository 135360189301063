import {
  CreateCampaignOrderDTO,
  CustomerAvailableCampaignDTO,
  IAvailableAttendance,
  IClinic,
  IDateRange,
  IOrder,
} from '@vacinas-net/shared'
import axios, { AxiosResponse } from 'axios'
import { isBefore } from 'date-fns'
import { calculateAvailableVouchers } from '../../pages/CreateCampaignOrder/utils'
import api from '../api'

interface GetCampaignAllowerUserListPayload {
  [key: string]: unknown
  document: string
}

interface GetAvailableAttendancesByZipCodePayload {
  [key: string]: unknown
  campaignId: string
  zipCode: string
}

interface GetClinicsByZipCodePayload {
  [key: string]: unknown
  campaignId: string
  zipCode: string
}

interface GetClinicOpeningHoursPayload {
  [key: string]: unknown
  campaignId: string
  clinicId: string
}

export interface CreateCampaignOrderPayload extends CreateCampaignOrderDTO {
  [key: string]: unknown
}

export const getCampaignAllowerUserList = async (
  payload: GetCampaignAllowerUserListPayload
) => {
  const result = await api.post(`/campaign/allowed-customer/external`, payload)
  const campaigns = result as unknown as CustomerAvailableCampaignDTO[]

  const endedCampaigns = campaigns
    .filter((item) => {
      if (!item.dateRange?.endDate) return false

      return isBefore(new Date(item.dateRange.endDate), new Date())
    })
    .sort((itemA, itemB) => {
      const createdAtItemA = new Date(itemA.createdAt!)
      const createdAtItemB = new Date(itemB.createdAt!)

      return createdAtItemB.getTime() - createdAtItemA.getTime()
    })

  const activeCampaigns = campaigns.filter((item) => {
    if (!item.dateRange?.endDate) return true

    return isBefore(new Date(), new Date(item.dateRange.endDate))
  })

  const withVouchersCampaigns = activeCampaigns
    .filter((item) => calculateAvailableVouchers(item) > 0)
    .sort((itemA, itemB) => {
      const createdAtItemA = new Date(itemA.createdAt!)
      const createdAtItemB = new Date(itemB.createdAt!)

      return createdAtItemB.getTime() - createdAtItemA.getTime()
    })

  const withoutVouchersCampaigns = activeCampaigns.filter(
    (item) => calculateAvailableVouchers(item) <= 0
  )

  return [
    ...withVouchersCampaigns,
    ...withoutVouchersCampaigns,
    ...endedCampaigns,
  ]
}

export const getAvailableAttendancesByZipCode = async (
  payload: GetAvailableAttendancesByZipCodePayload
) => {
  try {
    const result = await api.post(
      `/campaign/available-attendance/external`,
      payload
    )
    return result as unknown as IAvailableAttendance[]
  } catch (error) {
    throw new Error('CEP não encontrado. Verifique e tente novamente')
  }
}

export const getClinicsByZipCode = async (
  payload: GetClinicsByZipCodePayload
) => {
  try {
    const result = await api.post(`/campaign/clinic/external`, payload)
    return result as unknown as IClinic[]
  } catch (error) {
    throw new Error('CEP não encontrado. Verifique e tente novamente')
  }
}

export const getClinicOpeningHours = async (
  payload: GetClinicOpeningHoursPayload
) => {
  const result = await api.post(
    `/campaign/clinic/delivery-window/external
  `,
    payload
  )
  return result as unknown as IDateRange[]
}

export const createCampaignOrder = async (
  payload: CreateCampaignOrderPayload
) => {
  try {
    const baseUrl = process.env.REACT_APP_API_ENTRYPOINT
    const result = await axios.post<IOrder>(
      `${baseUrl}/order/campaign`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return result.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const response: AxiosResponse | undefined = error.response

    const message = translateErrorMessage(response?.data?.message)

    throw new Error(message)
  }
}

const translateErrorMessage = (englishMessage?: string) => {
  switch (englishMessage) {
    case 'An attendance for the given customer already exists on this campaign':
      return 'Já existe um agendamento para este CPF nesta campanha'
    case 'Clinic is not available for the given deliveryWindow':
      return 'O horário selecionado está indisponível. Selecione outra faixa de horário.'
    default:
      return 'Tente novamente mais tarde'
  }
}
