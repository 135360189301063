import React, { useState } from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material'
import { StyledSelectSquare } from '../../../../utils/sharedStyles'
import { useSelector } from '../../../../state/store'

import {
  calculateVacineDateOptions,
  groupAvailableAttendances,
} from '../../utils'

import { useDispatch } from 'react-redux'
import {
  actions,
  CreateCampaignOrderStateOrder,
} from '../../../../state/createCampaignOrder'
import { LocationOn } from '@mui/icons-material'
import { parseAddress } from '../../../../utils/utils'
import { PlaceFormDesktopContainer } from '../styles'

const PlaceForm = () => {
  const dispatch = useDispatch()
  const availableAttendances = useSelector(
    (state) => state.createCampaignOrder.availableAttendances
  )
  const errors = useSelector(
    (state) => state.createCampaignOrder.createOrderFormErrors
  )
  const formData = useSelector(
    (state) => state.createCampaignOrder.createOrderForm
  )

  const availableAttendanceGroups =
    groupAvailableAttendances(availableAttendances)
  const places = Object.keys(availableAttendanceGroups)

  const [vaccineDateOptions, setVaccineDateOptions] = useState<
    { label: string; value: string }[]
  >(calculateVacineDateOptions(formData.placeName, availableAttendanceGroups))

  const setOrderValue = (params: Partial<CreateCampaignOrderStateOrder>) => {
    const newErrors: { [key: string]: string } = {}
    const keys = Object.keys(params)
    keys.forEach((key) => {
      newErrors[key] = ''
    })
    dispatch(actions.setCreateOrderFormErrors(newErrors))
    dispatch(actions.setCreateOrderFormOrder(params))
  }

  const onChangePlaceName = (placeName: string) => {
    setOrderValue({ availableAttendanceId: '', placeName })

    const vaccineDateOptions = calculateVacineDateOptions(
      placeName,
      availableAttendanceGroups
    )
    setVaccineDateOptions(vaccineDateOptions)
  }

  const getSelectedPlaceAddress = () => {
    const addressList = availableAttendances?.map(
      (attendance) => attendance.address
    )
    const selectedAddress = addressList?.find(
      (address) => address.name === formData.placeName
    )

    return parseAddress(selectedAddress)
  }
  return (
    <PlaceFormDesktopContainer item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl
            disabled={Boolean(places.length === 0)}
            fullWidth
            size="small"
            error={!!errors.placeName}
          >
            <InputLabel>Local*</InputLabel>
            <StyledSelectSquare
              value={formData.placeName}
              onChange={(e) => onChangePlaceName(e.target.value as string)}
              label="Local"
            >
              {places.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </StyledSelectSquare>
          </FormControl>
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors.placeName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.availableAttendanceId}
          >
            <InputLabel>Data*</InputLabel>
            <StyledSelectSquare
              disabled={!formData.placeName}
              value={formData.availableAttendanceId}
              onChange={(e) =>
                setOrderValue({
                  availableAttendanceId: e.target.value as string,
                })
              }
              label="Data"
            >
              {vaccineDateOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </StyledSelectSquare>
          </FormControl>
          <Typography color="error" variant="body2" marginTop={0.5}>
            {errors.availableAttendanceId}
          </Typography>
        </Grid>
      </Grid>
      <Grid marginTop={1} container xs={12}>
        <LocationOn color="primary" />
        <Typography marginLeft={0.3} variant="body1" color="textSecondary">
          Endereço:
        </Typography>
        <Typography marginLeft={0.6} variant="body1" color="primary">
          {getSelectedPlaceAddress()}
        </Typography>
      </Grid>
    </PlaceFormDesktopContainer>
  )
}

export default PlaceForm
