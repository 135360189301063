import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Campaign from '../pages/Campaign'
import Layout from '../components/elements/Layout'
import Login from '../pages/Login'
import { RequireEnterpriseAuth, RequireCustomerAuth } from './utils'
import Acceptance from '../pages/Acceptance'
import PageNotFound from '../pages/PageNotFound'
import CampaignDetails from '../pages/CampaignDetails'
import VoucherUsageReport from '../pages/VoucherUsageReport'
import OrderReport from '../pages/OrderReport'
import { ToastContainer } from 'react-toastify'
import Errors from '../components/modules/Error'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from '../state/store'
import { getBaseRoute } from '../utils/utils'
import Unauthorized from '../pages/Unauthorized/Unauthorized'
import CustomerOrders from '../pages/CustomerOrders'
import CustomerAttendances from '../pages/CustomerAttendances'
import SearchCampaignStep from '../pages/CreateCampaignOrder/SearchCampaignStep'
import CampaignNotFoundStep from '../pages/CreateCampaignOrder/CampaignNotFoundStep'
import CampaignListStep from '../pages/CreateCampaignOrder/CampaignListStep/index'
import ScheduleOrderStep from '../pages/CreateCampaignOrder/ScheduleOrderStep'
import ScheduleOrderReceipt from '../pages/CreateCampaignOrder/ScheduleOrderReceipt'

const AppRoutes = () => {
  const user = useSelector((state) => state.user)

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              state={{ from: location.href }}
              replace
              to={getBaseRoute(user.user.roles)}
            />
          }
        />
        <Route
          path="/customer-attendance/:attendanceUUID"
          element={<CustomerAttendances />}
        />
        <Route path="/campaign/order">
          <Route path="/campaign/order" element={<SearchCampaignStep />} />
          <Route
            path="/campaign/order/not-found"
            element={<CampaignNotFoundStep />}
          />
          <Route path="/campaign/order/list" element={<CampaignListStep />} />
          <Route
            path="/campaign/order/schedule"
            element={<ScheduleOrderStep />}
          />
          <Route
            path="/campaign/order/receipt"
            element={<ScheduleOrderReceipt />}
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route element={<Layout />}>
          <Route element={<RequireEnterpriseAuth />}>
            <Route path="/campaign" element={<Campaign />} />
            <Route
              path="/campaign/:campaignId/acceptance"
              element={<Acceptance />}
            />
            <Route path="/campaign/:campaignId" element={<CampaignDetails />} />
            <Route
              element={<VoucherUsageReport />}
              path="/campaign/:campaignId/voucherReport"
            />
            <Route
              element={<OrderReport />}
              path="/campaign/:campaignId/orderReport"
            />
          </Route>
          <Route element={<RequireCustomerAuth />}>
            <Route element={<CustomerOrders />} path="/order" />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Errors />
      <ToastContainer autoClose={3000} />
    </BrowserRouter>
  )
}

export default AppRoutes
