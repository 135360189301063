import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from '../../../../state/store'
import { mainTheme } from '../../../../styles/mainTheme'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import SendIcon from '@mui/icons-material/Send'
import {
  calculateGivenVouchers,
  calculateUsedVouchersAmount,
} from '../../utils'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import {
  DataContainer,
  AvailableVoucherContainer,
  IconContainer,
  UsedVouchersContainer,
} from './styles'
import { AddLocationAlt } from '@mui/icons-material'

const Menu = () => {
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const currentStep = useSelector(
    (state) => state.createCampaignOrder.createOrderFormStep
  )

  const steps = [
    {
      label: 'Preencher informações',
      icon: PersonOutlineOutlinedIcon,
      key: 'form',
    },
    {
      label: 'Escolher local e data',
      icon: AddLocationAlt,
      key: 'place',
    },
    {
      label: 'Revisar e agendar',
      icon: SendIcon,
      key: 'review',
    },
  ]

  if (!campaign) return <></>

  const usedVouchers = calculateUsedVouchersAmount(campaign)
  const givenVouchers = calculateGivenVouchers(campaign)

  return (
    <Grid
      container
      gap={2}
      paddingLeft={2.5}
      paddingRight={2.5}
      flex="1"
      flexDirection={'column'}
      flexWrap="unset"
    >
      <Grid
        item
        xs={12}
        gap={1}
        display="flex"
        flexDirection="column"
        style={{ flexBasis: 0 }}
      >
        <Typography color="primary" variant="body1">
          Campanha
        </Typography>
        <UsedVouchersContainer item padding={1.5} container>
          <Typography color="primary" variant="body1">
            <b>{campaign.name}</b>
          </Typography>
          <AvailableVoucherContainer>
            <LocalActivityIcon color="primary" />
            <Typography color="black" variant="body1">
              Utilizando vale {usedVouchers + 1} de {givenVouchers}
            </Typography>
          </AvailableVoucherContainer>
        </UsedVouchersContainer>
      </Grid>

      <Grid item xs={12} gap={1} display="flex" flexDirection="column" flex="1">
        <Typography color="primary" variant="body1">
          Etapas
        </Typography>
        {steps.map((step) => {
          const Icon = step.icon
          const typographyColor =
            step.key === currentStep
              ? 'primary'
              : mainTheme.palette.text.disabled

          const iconColor = step.key === currentStep ? 'primary' : 'disabled'

          return (
            <DataContainer key={step.key} item padding={1}>
              <IconContainer>
                <Icon style={{ width: 24, height: 24 }} color={iconColor} />
              </IconContainer>
              <Typography color={typographyColor} variant="body1">
                <b>{step.label}</b>
              </Typography>
            </DataContainer>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default Menu
