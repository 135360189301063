import { Paper, Typography } from '@mui/material'
import styled from 'styled-components'
import { mainTheme } from '../../../styles/mainTheme'

export const Container = styled(Paper)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 180px;

  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  overflow: none;
  margin-bottom: ${mainTheme.spacing(2)}px;
  padding: ${mainTheme.spacing(2)}px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0.2rem 0.8rem;
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
`
export const DateContainer = styled.div`
  margin-top: ${mainTheme.spacing(1)}px;
  margin-bottom: ${mainTheme.spacing(1)}px;
`
export const CouponData = styled.div`
  display: flex;
  flex-direction: column;
`

export const CouponName = styled(Typography)`
  margin-top: ${mainTheme.spacing(1)}px;
  margin-left: 20%;
  font-weight: bold !important;
  color: ${mainTheme.palette.grey[300]};
`
