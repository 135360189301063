import { Typography } from '@mui/material'
import React from 'react'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import DangerousIcon from '@mui/icons-material/Dangerous'
import { calculateAvailableVouchers, calculateGivenVouchers } from '../utils'
import { mainTheme } from '../../../styles/mainTheme'
import styled from 'styled-components'
import { CustomerAvailableCampaignDTO } from '@vacinas-net/shared'
import { useSelector } from '../../../state/store'
import { useDispatch } from 'react-redux'
import { actions } from '../../../state/createCampaignOrder'
import { isBefore } from 'date-fns'

export interface CampaignCardProps {
  campaign: CustomerAvailableCampaignDTO
}

const AvailableVoucherContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${mainTheme.spacing(0.5)}px;
  margin-top: ${mainTheme.spacing(0.5)}px;
`

const CampaignCardWrapper = styled.button<{
  $isActive: boolean
  $isEndedCampaign: boolean
  $hasNoAvailableVouchers: boolean
}>`
  text-align: left;

  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) =>
    props.$isActive
      ? mainTheme.palette.primary.main
      : mainTheme.palette.primary.light};
  background-color: ${(props) =>
    props.$isEndedCampaign
      ? mainTheme.palette.grey['600']
      : mainTheme.palette.primary.light};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.$hasNoAvailableVouchers ? '50%' : '100%')};

  :active {
    transform: scale(${(props) => (props.disabled ? 1 : 0.99)});
  }
`

const CampaignCard = (props: CampaignCardProps) => {
  const dispatch = useDispatch()
  const selectedCampaign = useSelector(
    (state) => state.createCampaignOrder.campaign
  )
  const { campaign } = props
  const availableVouchers = calculateAvailableVouchers(campaign)
  const givenVouchersAmount = calculateGivenVouchers(campaign)
  const isEndedCampaign =
    campaign.dateRange?.endDate &&
    isBefore(new Date(campaign.dateRange.endDate), new Date())

  const resolveAvailableVoucherLabel = (
    availableVouchers: number,
    givenVouchersAmount: number
  ) => {
    if (isEndedCampaign) return `Campanha encerrada`

    if (availableVouchers <= 0) return 'Todos os vales foram utilizados'
    return `${availableVouchers} de ${givenVouchersAmount}  vales vacina`
  }

  return (
    <CampaignCardWrapper
      $isEndedCampaign={!!isEndedCampaign}
      $isActive={selectedCampaign?._id === campaign._id}
      $hasNoAvailableVouchers={availableVouchers <= 0 && !isEndedCampaign}
      onClick={() => dispatch(actions.setCampaign(campaign))}
      disabled={availableVouchers <= 0 || isEndedCampaign}
    >
      <Typography variant="h2" color={isEndedCampaign ? 'white' : 'primary'}>
        {campaign?.name}
      </Typography>
      <AvailableVoucherContainer>
        {!isEndedCampaign && <LocalActivityIcon color="primary" />}
        {isEndedCampaign && (
          <DangerousIcon color="primary" style={{ color: 'white' }} />
        )}
        <Typography variant="body1" color={isEndedCampaign ? 'white' : 'black'}>
          {resolveAvailableVoucherLabel(availableVouchers, givenVouchersAmount)}
        </Typography>
      </AvailableVoucherContainer>
    </CampaignCardWrapper>
  )
}

export default CampaignCard
