import { createSlice } from '@reduxjs/toolkit'
import { CreateCampaignOrderState } from './types'
import { ShippingPolicy } from '@vacinas-net/shared'

const isDev = process.env.REACT_APP_NODE_ENV === 'development'

const initialState: CreateCampaignOrderState = {
  customer: {
    document: isDev ? '233.964.760-69' : '',
    zipCode: isDev ? '13560-648' : '',
  },
  isLoading: false,
  campaign: undefined,
  campaigns: undefined,
  createOrderForm: {
    fullName: '',
    birthdate: '',
    email: '',
    document: '',
    phone: '',
    placeName: '',
    availableAttendanceId: '',
  },
  createOrderFormErrors: {
    fullName: '',
    birthdate: '',
    email: '',
    document: '',
    phone: '',
    placeName: '',
    availableAttendanceId: '',
  },
  placeStepErrors: {
    selectedClinic: '',
    vaccineShotDay: '',
    vaccineShotTime: '',
  },
  createOrderFormStep: 'form',
  createdOrder: undefined,
  shippingPolicy: ShippingPolicy.Clinic,
}

export const slice = createSlice({
  name: 'createCampaignOrder',
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      state.customer = {
        ...state.customer,
        ...action.payload,
      }
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setCampaign: (state, action) => {
      state.campaign = action.payload
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload
    },
    setAvailableAttendances: (state, action) => {
      state.availableAttendances = action.payload
    },
    setSearchedZipCode: (state, action) => {
      state.searchedZipCode = action.payload
    },
    setSearchedClinicsByZipCode: (state, action) => {
      state.searchedClinicsByZipCode = action.payload
    },
    setCreateOrderFormOrder: (state, action) => {
      state.createOrderForm = {
        ...state.createOrderForm,
        ...action.payload,
      }
    },
    setCreateOrderFormErrors: (state, action) => {
      state.createOrderFormErrors = {
        ...state.createOrderFormErrors,
        ...action.payload,
      }
    },
    setPlaceStepErrors: (state, action) => {
      state.placeStepErrors = {
        ...state.placeStepErrors,
        ...action.payload,
      }
    },
    setCreateOrderFormStep: (state, action) => {
      state.createOrderFormStep = action.payload
    },
    setCreatedOrder: (state, action) => {
      state.createdOrder = action.payload
    },
    setSelectedClinic: (state, action) => {
      state.selectedClinic = action.payload
    },
    setVaccineShotDay: (state, action) => {
      state.vaccineShotDay = action.payload
    },
    setVaccineShotTime: (state, action) => {
      state.vaccineShotTime = action.payload
    },
    setVaccinationPlace: (state, action) => {
      state.shippingPolicy = action.payload
    },
  },
})

export const actions = { ...slice.actions }

export const reducer = slice.reducer
