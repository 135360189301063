import { useMediaQuery, useTheme } from '@material-ui/core'
import { IAddress, isCustomer, isEnterprise } from '@vacinas-net/shared'

export const googleApiUrl = process.env.REACT_APP_GOOGLE_MAP_URL_KEY as string

export const useIsMobile = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return isMobile
}

export const parseAddress = (adressObject: IAddress | undefined) => {
  if (!adressObject) return '-'
  const streetName = adressObject.streetName || ''
  const streetNumber = adressObject.streetNumber || ''
  const neighborhood = adressObject.neighborhood || ''
  const city = adressObject.city || ''
  const state = adressObject.state || ''
  const complement = adressObject.complement || ''
  return [streetName, streetNumber, neighborhood, complement, city, state]
    .join(', ')
    .replace(/, , /g, ', ')
}

export const valueInCentsToBRL = (centsValue: number) => {
  return centsValue ? `R$${(centsValue / 100).toFixed(2)}` : '-'
}

export const getBaseRoute = (permissions: string[] | undefined) => {
  if (isEnterprise(permissions)) {
    return '/campaign'
  }
  if (isCustomer(permissions)) {
    return '/order'
  }
  if (!isEnterprise(permissions) && !isCustomer(permissions)) {
    return '/unauthorized'
  }
  return '/login'
}

export const formatDocument = (document: string | undefined) => {
  if (!document) return '-'
  return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const datePattern = 'dd/MM/yyyy'
export const timePattern = 'HH:mm'

export const downloadFile = (
  content: string,
  nameWithExtension: string
): void => {
  const downloadUrl = window.URL.createObjectURL(new Blob([content]))
  const link = document.createElement('a')
  link.target = '_blank'
  link.download = nameWithExtension
  link.href = downloadUrl
  document.body.appendChild(link)
  link.click()
  link.remove()
}
