import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SubmitSection from './SubmitSection'
import PlaceSection from './PlaceSection'
import PersonalDataSection from './PersonalDataSection'
import { mainTheme } from '../../../../styles/mainTheme'
import styled from 'styled-components'

const ScheduleOrderReviewGrid = styled(Grid)`
  width: 100%;
  gap: ${mainTheme.spacing(2.5)}px;

  ${mainTheme.breakpoints.up('md')} {
    max-width: 700px;
  }
`

const ScheduleOrderReview = () => {
  return (
    <ScheduleOrderReviewGrid container>
      <Grid item xs={12}>
        <Typography color="primary" variant="h1" marginBottom={1.5}>
          Confirme as informações
        </Typography>
        <Typography variant="h2" fontWeight={'normal'}>
          Certifique-se que todos os dados estão preenchidos corretamente e
          finalize seu agendamento
        </Typography>
      </Grid>

      <Grid item xs={12} container>
        <PersonalDataSection />
        <PlaceSection />
      </Grid>

      <SubmitSection />
    </ScheduleOrderReviewGrid>
  )
}

export default ScheduleOrderReview
