import React from 'react'
import { useSelector } from '../../../../state/store'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import { RowWrapper, iconStyle } from './styles'
import { format } from 'date-fns'
import { datePattern, formatDocument } from '../../../../utils/utils'

const PersonalDataSection = () => {
  const formData = useSelector(
    (state) => state.createCampaignOrder.createOrderForm
  )

  return (
    <Grid
      item
      xs={12}
      md={6}
      container
      gap={1}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      flexWrap="unset"
    >
      <Typography marginBottom={1.5} color="primary" variant="h2">
        Dados pessoais
      </Typography>
      <RowWrapper item xs={12} container gap={1}>
        <PersonOutlineOutlinedIcon style={iconStyle} color="primary" />
        <Typography variant="body1">{formData.fullName}</Typography>
      </RowWrapper>
      <RowWrapper item xs={12} container gap={1}>
        <CalendarTodayOutlinedIcon style={iconStyle} color="primary" />
        <Typography variant="body1">
          {format(new Date(formData.birthdate), datePattern)}
        </Typography>
      </RowWrapper>
      <RowWrapper item xs={12} container gap={1}>
        <EmailOutlinedIcon style={iconStyle} color="primary" />
        <Typography variant="body1">{formData.email}</Typography>
      </RowWrapper>
      <RowWrapper item xs={12} container gap={1}>
        <BadgeOutlinedIcon style={iconStyle} color="primary" />
        <Typography variant="body1">
          {formatDocument(formData.document)}
        </Typography>
      </RowWrapper>
      <RowWrapper item xs={12} container gap={1}>
        <LocalPhoneOutlinedIcon style={iconStyle} color="primary" />
        <Typography variant="body1">{formData.phone}</Typography>
      </RowWrapper>
    </Grid>
  )
}

export default PersonalDataSection
