import { Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { StyledTextFieldSquare } from '../../utils/sharedStyles'
import { actions } from '../../state/createCampaignOrder'
import { useSelector } from '../../state/store'
import { cpf } from 'cpf-cnpj-validator'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import {
  CreateCampaignOrderContent,
  CreateCampaignOrderLayout,
  TitleGrid,
  InputGrid,
  SubmitGrid,
} from './styles'

import { getCampaignAllowerUserList } from '../../services/createCampaignOrder'
import { errorActions } from '../../state/errors'
import CreateCampaignOrderFooter from './CreateCampaignOrderFooter'

const SearchCampaignStep = () => {
  const [errors, setErrors] = useState({
    document: '',
  })

  const customer = useSelector((state) => state.createCampaignOrder.customer)
  const isLoading = useSelector((state) => state.createCampaignOrder.isLoading)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const validateCustomer = () => {
    const hasValidDocument = cpf.isValid(customer.document)

    setErrors({
      document: hasValidDocument ? '' : 'CPF inválido',
    })

    return hasValidDocument
  }

  const searchCampaigns = async () => {
    const validation = validateCustomer()

    if (!validation) return

    dispatch(actions.setLoading(true))

    try {
      const campaigns = await getCampaignAllowerUserList({
        document: customer.document.replace(/[^\d]/g, ''),
      })

      if (!campaigns?.length) return navigate('/campaign/order/not-found')

      dispatch(actions.setCampaigns(campaigns))

      navigate('/campaign/order/list')
    } catch (error) {
      const errorMessage = (error as Error).message

      dispatch(
        errorActions.setError({
          errorId: String(Date.now()),
          errorMessage,
        })
      )
    } finally {
      dispatch(actions.setLoading(false))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setCustomerValue = (key: string, value: any) => {
    dispatch(actions.setCustomer({ [key]: value }))
    setErrors({
      ...errors,
      [key]: '',
    })
  }

  return (
    <CreateCampaignOrderLayout>
      <CreateCampaignOrderContent container xs={12}>
        <TitleGrid item>
          <Typography color="primary" variant={'h1'}>
            É um prazer ter você aqui!
          </Typography>
          <Typography marginTop={1} color="black" variant={'body1'}>
            Para agendar sua vacinação precisamos de algumas informações.
            Preencha os campos abaixo e vamos começar.
          </Typography>
        </TitleGrid>
        <InputGrid item>
          <div>
            <ReactInputMask
              mask="999.999.999-99"
              disabled={false}
              value={customer.document}
              onChange={(event) => {
                setCustomerValue('document', event.target.value)
              }}
            >
              {() => (
                <StyledTextFieldSquare
                  color="primary"
                  label="CPF"
                  size="small"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: Boolean(customer.document) }}
                  error={Boolean(errors.document)}
                />
              )}
            </ReactInputMask>
            <Typography color="error" variant="body2" marginTop={0.5}>
              {errors.document}
            </Typography>
          </div>
        </InputGrid>
        <SubmitGrid item>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading || !!errors.document}
            onClick={() => searchCampaigns()}
            variant="contained"
            size="large"
          >
            Buscar Campanhas
          </LoadingButton>
        </SubmitGrid>
      </CreateCampaignOrderContent>
      <CreateCampaignOrderFooter />
    </CreateCampaignOrderLayout>
  )
}

export default SearchCampaignStep
