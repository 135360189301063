import { Grid } from '@mui/material'
import React from 'react'
import { StyledTextFieldSquare } from '../../../../utils/sharedStyles'
import ReactInputMask from 'react-input-mask'
import PlaceForm from './PlaceForm'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../../state/store'
import { actions } from '../../../../state/createCampaignOrder'

const SelectEnterprisePlace = () => {
  const dispatch = useDispatch()
  const zipCode = useSelector(
    (state) => state.createCampaignOrder.searchedZipCode
  )

  return (
    <>
      <Grid marginTop={1} spacing={2} marginBottom={1} container xs={12}>
        <Grid item xs={6}>
          <ReactInputMask
            mask="99999-999"
            disabled={false}
            value={zipCode}
            onChange={(event) => {
              dispatch(actions.setSearchedZipCode(event.target.value))
            }}
          >
            {() => (
              <StyledTextFieldSquare
                color="primary"
                label="CEP"
                size="small"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: Boolean(zipCode) }}
              />
            )}
          </ReactInputMask>
        </Grid>
        <PlaceForm />
      </Grid>
    </>
  )
}

export default SelectEnterprisePlace
