import { Button, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  CreateCampaignOrderStateOrder,
  PlaceStepErrors,
  actions,
} from '../../../../state/createCampaignOrder'

import { mainTheme } from '../../../../styles/mainTheme'
import { useSelector } from '../../../../state/store'
import {
  getAvailableAttendancesByZipCode,
  getClinicsByZipCode,
} from '../../../../services/createCampaignOrder'
import { toast } from 'react-toastify'
import SelectClinic from './SelectClinic'
import SelectEnterprisePlace from './SelectEnterprisePlace'
import { ShippingPolicy } from '@vacinas-net/shared'
import VaccinationPlaceSelector from './VaccinationPlaceSelector'

const PlaceDataForm = () => {
  const dispatch = useDispatch()
  const formData = useSelector(
    (state) => state.createCampaignOrder.createOrderForm
  )
  const campaign = useSelector((state) => state.createCampaignOrder.campaign)
  const vaccineShotDay = useSelector(
    (state) => state.createCampaignOrder.vaccineShotDay
  )
  const vaccineShotTime = useSelector(
    (state) => state.createCampaignOrder.vaccineShotTime
  )

  const clinic = useSelector(
    (state) => state.createCampaignOrder.selectedClinic
  )
  const searchedZipCode = useSelector(
    (state) => state.createCampaignOrder.searchedZipCode
  )
  const hasValidZipCode = searchedZipCode
    ? searchedZipCode.split('_').join('').length === 9
    : false

  const shippingPolicy = useSelector(
    (state) => state.createCampaignOrder.shippingPolicy
  )

  useEffect(() => {
    if (hasValidZipCode && shippingPolicy === ShippingPolicy.Company)
      searchZipCode()
    if (hasValidZipCode && shippingPolicy === ShippingPolicy.Clinic)
      fetchSearchedClinicsByZipCode()
  }, [searchedZipCode])

  const searchZipCode = async () => {
    try {
      if (campaign?._id) {
        const response = await getAvailableAttendancesByZipCode({
          zipCode: searchedZipCode!.replace(/[^\d]/g, ''),
          campaignId: campaign._id,
        })
        if (response.length === 0) {
          throw new Error(
            'Não encontramos nenhuma opção de local perto do CEP informado'
          )
        }
        dispatch(actions.setAvailableAttendances(response))
      }
    } catch (error) {
      console.error(error)
      toast(String(error), { type: 'error' })
    }
  }

  const fetchSearchedClinicsByZipCode = async () => {
    const payload = {
      campaignId: String(campaign?._id),
      zipCode: searchedZipCode!.replace(/[^\d]/g, ''),
    }

    try {
      const response = await getClinicsByZipCode(payload)
      if (response.length === 0) {
        throw new Error(
          'Não encontramos nenhuma opção de clínica perto do CEP informado'
        )
      }
      dispatch(actions.setSearchedClinicsByZipCode(response))
    } catch (error) {
      dispatch(actions.setSearchedClinicsByZipCode([]))

      console.error(error)
      toast(String(error), { type: 'error' })
    }
  }

  const goToNextStep = () => {
    let isValid = true
    if (shippingPolicy === ShippingPolicy.Company) {
      const newErrors: Partial<CreateCampaignOrderStateOrder> = {}
      const requiredErrors = checkRequiredInputs()

      const keys = Object.keys(formData)

      keys.forEach((key) => {
        const indexKey = key as keyof CreateCampaignOrderStateOrder
        const errorMessage = requiredErrors[indexKey]
        newErrors[indexKey] = errorMessage
        if (errorMessage) isValid = false
      })

      dispatch(actions.setCreateOrderFormErrors(newErrors))
    }

    if (shippingPolicy === ShippingPolicy.Clinic) {
      const newErrors: Partial<PlaceStepErrors> = {
        selectedClinic: clinic
          ? ''
          : 'Por favor selecione uma clínica antes de prosseguir',
        vaccineShotDay: vaccineShotDay ? '' : 'Campo Obrigatório',
        vaccineShotTime: vaccineShotTime ? '' : 'Campo Obrigatório',
      }
      if (
        newErrors.selectedClinic ||
        newErrors.vaccineShotDay ||
        newErrors.vaccineShotTime
      ) {
        isValid = false
      }

      dispatch(actions.setPlaceStepErrors(newErrors))
    }

    if (!isValid) return
    dispatch(actions.setCreateOrderFormStep('review'))
  }

  const checkRequiredInputs = () => {
    const errors: Partial<CreateCampaignOrderStateOrder> = {}
    const keys = Object.keys(formData)

    keys.forEach((key) => {
      const indexKey = key as keyof CreateCampaignOrderStateOrder

      if (formData[indexKey]) {
        errors[indexKey] = ''
      } else {
        errors[indexKey] = 'Campo obrigatório'
      }
    })

    return errors as CreateCampaignOrderStateOrder
  }

  const goBack = () => {
    dispatch(actions.setCreateOrderFormStep('form'))
  }

  return (
    <Grid item xs={12}>
      <VaccinationPlaceSelector />
      {shippingPolicy === ShippingPolicy.Clinic && <SelectClinic />}
      {shippingPolicy === ShippingPolicy.Company && <SelectEnterprisePlace />}
      <Grid container xs={12}>
        <Grid
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          item
          xs={12}
        >
          <Button onClick={() => goBack()} variant="text" size="small">
            {'< Alterar dados'}
          </Button>
          <Button
            onClick={() => {
              goToNextStep()
            }}
            variant="contained"
            size="large"
            color="primary"
            style={{ marginLeft: mainTheme.spacing(1) }}
          >
            Próximo
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PlaceDataForm
